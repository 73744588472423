import { gql, useMutation } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Typography, Paper, Button, List, ListItem, TextField, CircularProgress, Box, Alert } from '@mui/material';

const GET_ANSWER = gql`
  mutation GetAnswer($question: String!) {
    getAnswer(question: $question)
  }
`;

const starterQuestions = [
    "What are the rules for noise regulations?",
    "What are the snow removal rules?",
    "Does the city code cover sidewalks?",
    "What are the requirements for building permits?",
    "Make a checklist for hosting a special event."
];

const HomePage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null);

    const [getAnswer, { data, loading, error }] = useMutation(GET_ANSWER);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (input.trim()) {
            const userMessage = { text: input, sender: 'user' };
            setMessages([...messages, userMessage]);

            try {
                const { data } = await getAnswer({ variables: { question: input } });

                const cleanedText = data.getAnswer.replace(/【\d+:\d+†source】/g, '');
                const botMessage = { text: formatBotResponse(cleanedText), sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            } catch (err) {
                console.error("Error fetching the answer:", err);
                const errorMessage = { text: "Sorry, there was an error processing your request.", sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, errorMessage]);
            } finally {
                setInput('');
            }
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const formatBotResponse = (text) => {
        return <ReactMarkdown>{text}</ReactMarkdown>;
    };

    const handleStarterQuestionClick = (question) => {
        setInput(question);
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h3" component="h1" gutterBottom align="center">
                Trinidad CO Municipal Code Assistant
            </Typography>

            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    About This Service
                </Typography>
                <Typography paragraph>
                    Welcome to the Trinidad, Colorado Municipal Code Assistant, a chat-based interface designed to help you
                    navigate and understand the city’s municipal codes. Powered by OpenAI's GPT-4, this tool allows you
                    to ask questions about local laws, ordinances, and regulations, and receive instant answers.
                </Typography>
                <Typography paragraph>
                    This assistant leverages advanced AI technology to interpret and present relevant sections of the
                    municipal code. While the AI strives to provide accurate and up-to-date information, please note
                    that it may not always reflect the latest legal changes or interpretations.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <p><strong>Disclaimer:</strong> The information provided by this service is for general informational purposes only and does not constitute legal advice. This service, its developers, and any associated parties <strong>are not affiliated</strong> with the City of Trinidad, Colorado, or any governmental organization. The City of Trinidad, Colorado, the developers, and any associated parties are not responsible for any errors or omissions in the content. The responses generated by this service are based on data processed using the OpenAI Assistant API, which operates under OpenAI's usage policies and guidelines. Please review OpenAI's <a href="https://openai.com/terms" target="_blank" rel='noreferrer'>Terms of Use</a> and <a href="https://openai.com/privacy" target="_blank" rel='noreferrer'>Privacy Policy</a> for more information. The content provided may not always reflect the most current legal standards or interpretations. For specific legal concerns, please consult a qualified attorney or the appropriate government officials.</p>
                </Typography>
            </Paper>

            <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Starter Questions
                </Typography>
                <List>
                    {starterQuestions.map((question, index) => (
                        <ListItem sx={{ border: '1px solid black', margin: 1 }} button key={index} onClick={() => handleStarterQuestionClick(question)}>
                            {question}
                        </ListItem>
                    ))}
                </List>
            </Paper>

            <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                <Box sx={{ overflowY: 'auto', marginBottom: 2 }}>
                    {messages.map((message, index) => (
                        <Box key={index} sx={{ marginBottom: 1, padding: 1, borderRadius: 1, bgcolor: message.sender === 'bot' ? 'lightblue' : 'lightgreen' }}>
                            {message.sender === 'bot' ? message.text : <Typography>{message.text}</Typography>}
                        </Box>
                    ))}
                    <div ref={messagesEndRef} />
                </Box>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Type your question here..."
                        sx={{ marginBottom: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : "Send"}
                    </Button>
                </form>
                {error && <Alert severity="error">Error: {error.message}</Alert>}
            </Paper>
        </Container>
    );
};

export default HomePage;
